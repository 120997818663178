import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { useTranslationContext } from "../../../context/TranslationContext"
import SignUp from "../../../components/account/pages/sign-up"

const canonicals = {
  ["en-US"]: "/account/sign-up",
  ["de-DE"]: "/de/konto/konto-erstellen",
}

const SignUpPage = ({ data }) => {
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "de-DE",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  return <SignUp data={data} />
}

export default SignUpPage

export const pageQuery = graphql`
  {
    tile: contentfulTile(
      title: { eq: "account-signup" }
      node_locale: { eq: "de-DE" }
    ) {
      id
      backgroundImage {
        gatsbyImageData
      }
    }
  }
`
